@import 'styles/sizes.scss';

section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:global(.right) {
    max-width: 2300px;
    align-items: flex-end;
  }

  & :global(.fullSection) {
    margin: $space-large 10%;
  }


  &:global(.basic) {
    p {
      margin-bottom: 16px;
      margin-right: unset;
    }

    ul {
      list-style: initial;

      li {
        margin-left: 20px;
      }
    }

    h1 {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 35px;
      margin-bottom: 30px;
    }

    h3 {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  section {
    min-height: auto;
    padding: 80px 0;
    box-sizing: border-box;
    
    & :global(.fullSection) {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  section {
    padding: 60px 0;
  }
}
