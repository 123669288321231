.feature-list {
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  &:not(.feature-list--small) {
    li {
      &:nth-of-type(5n) {
        padding-left: 0;
      }
    }
  }

  li {
    flex: 0 1 calc(25% - 80px);
    padding: 0 40px;
    margin-bottom: 25px;
    position: relative;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 70px / 2);
      height: 70px;
      width: 2px;
      background-image: linear-gradient(#3A3A3A 60%, rgba(255,255,255, 0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
    }
  }

  &--small {
    li {
      flex: 0 1 calc(20% - 80px);

      &:nth-of-type(6n) {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .feature-list {
    display: block;
  
    li {
      width: 50%;
      display: inline-block;
      padding: 10px 0;
      box-sizing: border-box;

      &:first-of-type {
        padding-right: 0;
      }

      &:nth-child(2n - 1) {
        padding-right: 20px;
      }

      &:nth-child(2n) {
        padding-left: 20px;
      }

      &:not(:last-of-type)::after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .feature-list {
    display: block;
  
    li {
      width: 100%;
      display: block;
      padding: 10 0;
      margin: 0;

      &:first-of-type {
        padding-right: 0;
      }

      &:nth-child(2n - 1) {
        padding-right: 0;
      }

      &:nth-child(2n) {
        padding-left: 0;
      }
    }
  }
}