@import 'styles/fonts.scss';

.inline-tag {
  padding: 3px 6px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 3px;
  background-color: #F1F5F8;
  color: #ACB8C8;
  font-family: $font-alt;
  font-weight: normal;
  text-transform: uppercase;
}
