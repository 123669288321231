@import 'src/styles/colours.scss';
@import 'src/styles/fonts.scss';

.iconBlock {
  img {
    height: 50px;
  }

  .iconTitleContainer {
    margin-top: 15px;
  
    h3 {
      margin: 5px 0;

      span {
        font-weight: 300;
        font-family: "OpenSans", sans-serif;
      }
    }

    .tag {
      padding: 3px 6px;
      border-radius: 3px;
      background-color: #f1f5f8;
      color: #acb8c8;
      font-family: $font-alt;
      font-size: 11px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}