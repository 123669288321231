@import 'styles/colours.scss';

.headerSection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 100px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1080px) {
  .content {
    margin: 0 auto;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding-top: 0;
  }
}
